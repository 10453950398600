import React from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { Box, BREAKPOINTS, Flex } from '@revolut/ui-kit'
import { Chat, Envelope, Profile } from '@revolut/icons'
import { useOnboardingData } from '@src/api/onboardingEmployeesV2'
import { ROUTES } from '@src/constants/routes'
import Loader from '@components/CommonSC/Loader'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageWrapper } from '@components/Page/Page'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import Title from '@src/pages/Forms/Candidate/InterviewProgress/components/Title'
import { useEmployeeProfileData } from '@src/pages/EmployeeProfile/Preview/hooks'
import { EmployeeEmails } from '@src/features/EmployeeEmails/EmployeeEmails'
import { pathToUrl } from '@src/utils/router'
import { OnboardingActions } from './components/OnboardingActions'
import { OnboardingSubtitle } from './components/OnboardingSubtitle'
import { Comments } from './Comments'
import { SummaryTab } from './Summary'

export const OnboardingTimelineV2 = () => {
  const params = useParams<{ id: string; onboardingId: string }>()
  const {
    data: employeeData,
    handler,
    sections,
  } = useEmployeeProfileData({
    employeeId: params.id,
  })
  const {
    data: onboardingData,
    isLoading,
    refetch: refetchOnboardingData,
  } = useOnboardingData(params.onboardingId)

  if (!employeeData || !onboardingData) {
    return (
      <PageWrapper>
        <Loader />
      </PageWrapper>
    )
  }

  const tabs = [
    {
      title: 'Overview',
      path: ROUTES.FORMS.ONBOARDING_TIMELINE_V2.OVERVIEW,
      to: pathToUrl(ROUTES.FORMS.ONBOARDING_TIMELINE_V2.OVERVIEW, params),
      icon: <Profile size={15} />,
      component: (
        <SummaryTab
          data={employeeData}
          status={onboardingData.status.id}
          primaryDataHandler={handler}
          sectionsData={sections}
        />
      ),
    },
    {
      title: 'Emails',
      path: ROUTES.FORMS.ONBOARDING_TIMELINE_V2.EMAILS,
      icon: <Envelope size={15} />,
      to: pathToUrl(ROUTES.FORMS.ONBOARDING_TIMELINE_V2.EMAILS, params),
      // TODO: add tab stats
      component: <EmployeeEmails employeeId={Number(params.id)} />,
    },
    {
      title: 'Comments',
      path: ROUTES.FORMS.ONBOARDING_TIMELINE_V2.COMMENTS,
      icon: <Chat size={15} />,
      to: pathToUrl(ROUTES.FORMS.ONBOARDING_TIMELINE_V2.COMMENTS, params),
      // TODO: add tab stats
      component: <Comments employeeId={params.id} onboardingId={params.onboardingId} />,
    },
  ]

  return (
    <>
      <PageWrapper>
        <PageHeader
          backUrl={ROUTES.APPS.LIFECYCLE.OFFBOARDING}
          title={
            <PageHeader.Title
              actions={
                <OnboardingActions
                  employee={employeeData}
                  onboardingData={onboardingData}
                  refetchData={refetchOnboardingData}
                />
              }
              labels={
                <OnboardingSubtitle onboardingData={onboardingData} loading={isLoading} />
              }
              maxWidth={{ all: '100%', lg: BREAKPOINTS.xl }}
              title={<Title name={employeeData.full_name} />}
            />
          }
        >
          <Box pb="s-32">
            <TabBarNavigation tabs={tabs} />
          </Box>
        </PageHeader>
        <Flex flex={1} flexDirection="column" minHeight={0} width="100%">
          <Box maxWidth={{ all: '100%', lg: BREAKPOINTS.xl }}>
            <Switch>
              {tabs.map(tab => (
                <Route exact path={tab.path} key={tab.path}>
                  {tab.component}
                </Route>
              ))}
            </Switch>
          </Box>
        </Flex>
      </PageWrapper>
    </>
  )
}
